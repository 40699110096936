<template>
  <v-container fluid>
    <v-card v-if="customer" style="padding-bottom: 20px">
      <v-tabs v-model="selectedIndex" background-color="#2E4089" dark>
        <v-tab @click="goTo('general')"> General </v-tab>
        <v-tab-item>
          <v-card-title
            v-if="$route.name == 'customer_new'"
            primary-title
            style="padding-bottom: 0px"
          >
            {{ $t('new customer') }}
          </v-card-title>
          <v-card-title v-else primary-title style="padding-bottom: 0px">
            {{ $t('customer') }} {{ customer.name }}
          </v-card-title>
          <v-form ref="form" lazy-validation>
            <v-card-text>
              <v-container>
                <v-row no-gutters dense>
                  <v-col cols="6">
                    <p>{{ $t('account_name') }}</p>
                    <v-text-field
                      ref="Name"
                      v-model="customer.name"
                      :rules="nameRules"
                      dense
                      solo
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" style="padding-left: 20px">
                    <p>{{ $t('company') }}</p>
                    <v-text-field
                      v-model="customer.legal_name"
                      :rules="required"
                      dense
                      solo
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="5">
                    <countrySelect
                      :default-country="customer.country"
                      @countryUpdate="countryUpdate"
                    />
                  </v-col>
                </v-row>
                <br />
                <v-row no-gutters dense>
                  <v-col cols="12">
                    <p>
                      {{ $t('address') }}
                    </p>
                    <v-text-field
                      v-model="customer.street_adress"
                      :rules="required"
                      dense
                      solo
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="3">
                    <p>
                      {{ $t('zip_code') }}
                    </p>
                    <v-text-field
                      v-model="customer.zip_code"
                      :rules="required"
                      dense
                      solo
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="1"></v-col>
                  <v-col cols="6"
                    ><p>
                      {{ $t('city') }}
                    </p>
                    <v-text-field
                      v-model="customer.city"
                      :rules="required"
                      dense
                      solo
                    >
                      {{ customer.city }}
                    </v-text-field></v-col
                  >
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="10">
                    <p>{{ $t('tva_number') }} ({{ $t('optional') }})</p>
                    <v-text-field v-model="customer.vat" dense solo>
                    </v-text-field>
                  </v-col>
                </v-row>
                <!--<v-row no-gutters dense>
                  <v-col cols="10">
                    <v-switch
                      v-model="customer.adapter_is_active"
                      inset
                      :label="`Activate adapter`"
                    ></v-switch>
                  </v-col>
                </v-row>-->
              </v-container>
            </v-card-text>
            <v-card-actions class="d-flex flex-row-reverse">
              <v-btn
                :loading="submitLoading"
                :disabled="submitLoading"
                style="margin: 10px"
                class="white--text notcapital"
                color="black"
                width="130"
                dense
                @click="submit"
              >
                <v-icon left> mdi-check-circle</v-icon>
                {{ $t('save') }}
              </v-btn>
              <v-btn
                style="margin: 10px"
                class="white--text notcapital"
                color="black"
                width="130"
                dense
                @click="back"
              >
                <v-icon left> mdi-arrow-left </v-icon>
                {{ $t('back') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-tab-item>
        <v-tab v-if="$route.name != 'customer_new'" @click="goTo('user')">
          Users
        </v-tab>
        <v-tab-item v-if="$route.name != 'customer_new'">
          <v-row style="padding: 20px">
            <v-col cols="2">
              <v-btn
                class="notcapital"
                color="secondary"
                style="padding: 10px"
                width="180px"
                small
                @click="newItem"
              >
                <v-icon left> mdi-plus </v-icon>
                {{ $t('add_user') }}
              </v-btn>
            </v-col>
            <v-col cols="6" />
            <v-col cols="4" style="text-align: right" justify-end>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            :search="search"
            :headers="headersUsers"
            :items="computedUsers"
            :items-per-page="5"
            class="elevation-1"
            :loading="loading"
            hide-default-footer
            :disable-pagination="true"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.last_name }}</td>
                <td>{{ item.first_name }}</td>
                <td v-if="appUser.is_superadmin">
                  <router-link
                    :to="{
                      name: 'customer_edit',
                      params: {
                        customer: item,
                        customer_id: item.customer_id
                      }
                    }, "
                  >
                    {{ item.customer_id }}
                  </router-link>
                </td>
                <td>{{ item.email }}</td>
                <td>{{ item.role.toLowerCase() }}</td>
                <td>{{ Status(item.okta_status) }}</td>
                <td>
                  <v-menu bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark icon v-bind="attrs" v-on="on">
                        <v-icon color="black">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-if="Status(item.okta_status) == $t('deactivated')"
                        @click="ActionUser('activateuser', item.email)"
                        >{{ $t('activate_user') }}</v-list-item
                      >
                      <v-list-item
                        v-if="Status(item.okta_status) != $t('deactivated')"
                        @click="ActionUser('deactivateuser', item.email)"
                        >{{ $t('deactivate_user') }}</v-list-item
                      >
                      <v-list-item
                        v-if="
                          Status(item.okta_status) != $t('deactivated') &&
                          Status(item.okta_status) != $t('suspended')
                        "
                        @click="ActionUser('suspenduser', item.email)"
                        >{{ $t('suspend_user') }}</v-list-item
                      >
                      <v-list-item
                        v-if="Status(item.okta_status) == $t('pending')"
                        @click="ActionUser('reactivateuser', item.email)"
                        >{{ $t('reactivate_user') }}</v-list-item
                      >
                      <v-list-item
                        v-if="Status(item.okta_status) == $t('suspended')"
                        @click="ActionUser('unsuspenduser', item.email)"
                        >{{ $t('unsuspend_user') }}</v-list-item
                      >
                      <v-list-item
                        v-if="Status(item.okta_status) != $t('deleted')"
                        @click="ActionUser('resetpassword', item.email)"
                        >{{ $t('reset_password') }}</v-list-item
                      >
                      <v-list-item @click="editItem(item)">
                        {{ $t('edit_properties') }}</v-list-item
                      >
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab v-if="$route.name != 'customer_new'" @click="goTo('setup')">
          Set up
        </v-tab>
        <v-tab-item v-if="$route.name != 'customer_new'">
          <v-card-title primary-title style="padding-bottom: 0px">
            Set up
          </v-card-title>
          <v-form ref="form" lazy-validation no-gutters dense>
            <v-card-text style="padding-top: 10px">
              <v-container style="padding-top: 0px">
                <v-row no-gutters dense>
                  <v-col cols="10">
                    <v-switch
                      v-model="customer.stack_is_active"
                      inset
                      :label="`Activate Stack`"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="10">
                    <v-switch
                      v-model="customer.deal_is_active"
                      inset
                      :label="`Activate Deal`"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="3">
                    <p>Bid reduction</p>
                    <v-text-field
                      v-model="customer.cpm_adjustment"
                      dense
                      solo
                      :rules="floatRule"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="d-flex flex-row-reverse">
              <v-btn
                :loading="submitLoading"
                :disabled="submitLoading"
                style="margin: 10px"
                class="white--text notcapital"
                color="black"
                width="130"
                dense
                @click="submit"
              >
                <v-icon left> mdi-check-circle</v-icon>
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-tab-item>

        <v-tab v-if="$route.name != 'customer_new'" @click="goTo('ssp')">
          SSP
        </v-tab>
        <v-tab-item v-if="$route.name != 'customer_new'">
          <v-card-title> SSP activation </v-card-title>
          <v-row style="margin-left: 20px; padding-top: 20px">
            <v-data-table
              :search="searchSSP"
              :headers="headersSSP"
              :items="sspstableList"
              class="elevation-1"
              :loading="loadingSSP"
              hide-default-footer
              disable-pagination
              style="width: 100%"
            >
              <template v-slot:item="{ item }">
                <tr
                  :style="[
                    item['name'] == 'test'
                      ? { 'background-color': '#eaeaea' }
                      : {}
                  ]"
                >
                  <td style="width: 10%; font-weight: bold; cursor: pointer">
                    <v-checkbox
                      v-model="item['is_activated']"
                      color="black"
                    ></v-checkbox>
                  </td>
                  <td style="width: 12%">{{ $t(item['name']) }}</td>
                  <td style="width: 16%">
                    <v-text-field
                      v-model="alias[item['name']]"
                      dense
                      solo
                      style="padding-top: 13px"
                      :rules="aliasRules"
                    >
                    </v-text-field>
                  </td>
                  <td style="width: 13%">
                    <v-text-field
                      v-model="item['cpm_adjustment']"
                      dense
                      solo
                      style="padding-top: 13px"
                      :rules="floatRule"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-autocomplete
                      v-model="item['country_list']"
                      :items="countriesShown"
                      item-text="name"
                      item-value="code"
                      label="Choose countries"
                      outlined
                      dense
                      multiple
                      style="padding-top: 13px; width: 100%"
                    >
                      <template v-slot:selection="{ index }">
                        <v-chip
                          close
                          style="margin-top: 5px; margin-bottom: 5px"
                          @click:close="remove(item['country_list'], index)"
                        >
                          <span>{{
                            getNameCountry(item['country_list'][index])
                          }}</span>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </td>
                  <td style="width: 20%; padding-right: 0px">
                    <tr style="padding-left: 10px">
                      <td style="padding-right: 0px">
                        <p
                          style="
                            font-size: 15px;
                            color: rgba(0, 0, 0, 0.6);
                            font-family: Roboto;
                            margin: 0px;
                            padding-right: 0px;
                            padding-bottom: 8px;
                            margin-left: 5px;
                          "
                          align="right"
                          justify-end
                        >
                          Include
                        </p>
                      </td>
                      <td style="padding-left: 5px">
                        <v-switch
                          v-model="item['exclude']"
                          label="Exclude"
                          style="padding: 0px; margin-left: 5px"
                        ></v-switch>
                      </td>
                    </tr>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-row>
          <v-row no-gutters dense>
            <v-col cols="12">
              <v-card-actions align="right" class="justify-end">
                <v-col cols="12">
                  <v-btn
                    width="200"
                    style="height: 40px"
                    class="white--text"
                    color="black"
                    :loading="submitLoading"
                    :disabled="submitLoading"
                    @click="submit"
                  >
                    {{ $t('save') }}
                  </v-btn>
                </v-col>
                <br />
              </v-card-actions>
            </v-col>
          </v-row>
          <!--<ssp @saveSspChanges="addRule" />-->
        </v-tab-item>
        <v-tab v-if="$route.name != 'customer_new'" @click="goTo('blocklist')">
          Blocklist
        </v-tab>
        <v-tab-item v-if="$route.name != 'customer_new'">
          <blocklist :customer="customer" @savecustomer="saveBlocklist" />
        </v-tab-item>
        <!--<v-tab @click="goTo('billing')"> Billing </v-tab>
        <v-tab-item>
          <v-card-title> Billing settings </v-card-title>
          <v-row v-if="updated == true" style="padding-top: 20px">
            <v-col>
              <v-alert dense text type="success" color="green">
                Rule updated successfully</v-alert
              >
            </v-col>
          </v-row>
          <v-col style="padding-top: 0px">
            <v-btn
              class="black--text notcapital"
              tile
              width="200"
              height="30"
              outlined
              style="margin-top: 20px"
              @click="showArchived = !showArchived"
            >
              <v-icon left>
                {{ showArchived ? 'mdi-earth' : 'mdi-archive' }}</v-icon
              >
              {{ showArchived ? 'Show active rules' : 'Show archived rules' }}
            </v-btn>
          </v-col>
          <archivedRules v-if="showArchived" :archivedrules="archivedRules">
          </archivedRules>
          <v-data-table
            v-if="showArchived == false"
            :headers="headers"
            :items="rules"
            class="elevation-1"
            hide-default-footer
            :disable-pagination="true"
            :loading="loadingrules"
          >
            <template v-slot:item="props">
              <tr>
                <td style="width: 9%">
                  {{ props.item.product }}
                </td>
                <td class="text-xs-right">
                  <v-container style="padding: 5px">
                    <sitesgroup :items="props.item.sites" />
                  </v-container>
                </td>
                <td style="width: 8%">
                  {{ props.item.cpm_value }}
                </td>
                <td style="width: 12%">
                  {{ format_date(props.item.start_date) }}
                </td>
                <td v-if="props.item.end_date != null" style="width: 12%">
                  {{ format_date(props.item.end_date) }}
                </td>
                <td v-else style="width: 12%">Undefined</td>
                <td style="width: 10%; padding: 0px">
                  <updateRule
                    :sites="sites"
                    :selectedsitesprop="props.item.sites"
                    :rules="rule"
                    :customer="customer"
                    :item="props.item"
                    @refreshData="getDataFromApi"
                  >
                  </updateRule>
                  <archiveRule :item="props.item" @ruleArchived="archiveRule">
                  </archiveRule>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-form
            v-if="showArchived == false"
            ref="billingform"
            lazy-validation
          >
            <v-row style="padding: 20px 10px">
              <v-col cols="12" align="left" justify="left">
                <span v-if="errorsrules.length > 0">
                  <v-alert
                    v-for="error in errorsrules"
                    :key="error"
                    color="#db2727"
                    type="error"
                    dense
                    >{{ error }}</v-alert
                  >
                </span>
              </v-col>
            </v-row>
            <v-divider />
            <v-container v-if="addingRule == true" style="padding-top: 20px">
              <formRule
                :selectedsitesprop="[]"
                :customer="customer"
                :actiontype="'add'"
                @ruleAdding="addRule"
                @cancel="addingRule = false"
                @RefreshSites="refreshSites"
                @refreshData="getDataFromApi"
              />
            </v-container>
            <v-form ref="form" lazy-validation>
              <v-row v-if="added == true" style="padding-top: 20px">
                <v-col>
                  <v-alert dense text type="success" color="green">
                    Rule added successfully</v-alert
                  >
                </v-col>
              </v-row>
              <v-card-actions
                v-if="addingRule == false"
                class="d-flex flex-row-reverse"
              >
                <v-btn
                  v-if="addingRule == false"
                  :loading="submitLoading"
                  :disabled="submitLoading"
                  style="margin: 10px"
                  class="white--text notcapital"
                  color="black"
                  width="130"
                  dense
                  @click="
                    $router.push(
                      $route.path + '?settings=billing' + '&rule=new'
                    ),
                      (addingRule = true)
                  "
                >
                  <v-icon left> mdi-plus</v-icon>
                  {{ $t('add_rule') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-form>
        </v-tab-item>-->
        <v-tab v-if="$route.name != 'customer_new'" @click="goTo('throttling')">
          Throttling
        </v-tab>
        <v-tab-item v-if="$route.name != 'customer_new'">
          <v-row style="padding: 20px">
            <v-card style="padding: 10px; padding-left: 12px">
              <div>
                <h4>
                  It is possible to combine performance, revenue, and
                  eco-responsibility!
                </h4>
                <br />
                <p>
                  Like many other industries, digital advertising players are
                  addressing the environmental impact of their operations and
                  are implementing solutions to tackle energy wastage. However,
                  these optimizations cannot occur at the expense of internet
                  users by degrading website performance, nor by compromising
                  the financial stability of publishers. Today, the key
                  challenge in terms of eco-responsibility is to find a balance
                  between reducing CO2 emissions and maintaining, or even
                  enhancing web performance and revenue.
                </p>
                <a v-if="showMore == false" @click="showMore = !showMore">
                  See more
                </a>
              </div>
              <v-col v-if="showMore" style="padding-left: 0px">
                <h4>
                  Our actions have an impact on the environment and each of our
                  individual efforts contributes to reducing our collective
                  carbon footprint.
                </h4>
                <br />
                <p>
                  Several approaches can be implemented to reduce CO2 emissions:
                  cloud-based exchanges, choosing eco-responsible data centers,
                  limiting video content, improving compaign targeting, etc.
                  However, each of these approaches has impacts on the revenue
                  of online advertising players.
                </p>
                <p>
                  One of the key approaches that best aligns with the previously
                  mentioned notion of balance is to apply the principle of
                  throttling. It enables rationalizing the volume of requests
                  sent to programmatic chain actors based on their response rate
                  or the revenue they generate. In short, throttling means
                  keeping only relevant requests for a more efficient website!
                </p>
                <h4>
                  If you don't do it for the carbon footprint, do it for your
                  web performance!
                </h4>
                <br />
                <p>
                  Sending requests that never or hardly ever succeed slows down
                  your website. By eliminating these requests, you can reduce
                  the bandwidth and therefore, display ads more quickly. This
                  principle benefits your search engine optimization, as fast
                  websites are favored by Google algorithms, and users who will
                  stay on your website longer.
                </p>
                <p>
                  Not to mention that sending a more high-performing inventory
                  to monetization partners increases the likelihood of it being
                  monetized in the future.
                </p>
                <h4>
                  In the long run, throttling also has a positive effect on your
                  revenue!
                </h4>
              </v-col>
              <a v-if="showMore" @click="showMore = !showMore"> See less </a>
            </v-card>
          </v-row>
          <v-row style="padding: 20px">
            <v-col cols="12">
              <strong
                >Optimize your Adstack by defining the right sampling to each
                category.</strong
              ></v-col
            >
          </v-row>
          <v-row style="padding-left: 20px; font-size: 15px">
            <v-col cols="12">
              You have the possibility to filter to have a more refined view
              :</v-col
            >
          </v-row>
          <v-row style="padding-left: 20px; padding-top: 10px">
            <v-col cols="12" style="padding: 0px 0px 10px 0px">
              <v-row>
                <v-icon style="padding: 0px 10px 0px 10px" size="28"
                  >mdi-filter-outline</v-icon
                >
                <v-col col="4" style="padding: 15px 0px 20px 0px">
                  <v-select
                    v-model="selectedSspFiltered"
                    label="Filter by SSP"
                    flat
                    dense
                    hide-details
                    small
                    multiple
                    clearable
                    item-text="name"
                    item-value="value"
                    :items="sspListFromApi"
                  >
                  </v-select>
                </v-col>
                <v-col cols="8" />
              </v-row>
            </v-col>
          </v-row>
          <v-row style="padding-left: 20px; font-size: 15px; padding-top: 20px">
            <v-col cols="10" style="padding-bottom: 5px">
              What each category represents :
            </v-col>
            <v-col
              cols="2"
              style="
                text-align: right;
                padding-right: 30px;
                padding-bottom: 0px;
              "
              justify-end
            >
              Last 7 days
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :search="search"
                :items="computedSeperatedThrottling"
                :items-per-page="5"
                class="elevation-1"
                :loading="loading"
                hide-default-footer
                :disable-pagination="true"
              >
                <template slot="header">
                  <tr style="font-size: 13px">
                    <th
                      v-for="header in headersThrottling"
                      :key="header.value"
                      style="border-bottom: 0.1px solid lightgray"
                    >
                      <p
                        v-if="
                          header.text == 'Classification' ||
                          header.text == '% Bids' ||
                          header.text == '% Total bid value' ||
                          header.text == '% Impressions' ||
                          header.text == '% Revenue' ||
                          header.text == '% Total bids value'
                        "
                        style="
                          text-align: left;
                          padding: 20px 0px 0px 5px;
                          font-size: 12px;
                        "
                      >
                        {{ header.text }}
                      </p>
                      <p
                        v-else-if="header.text == 'Total bid value ($)'"
                        style="
                          text-align: right;
                          padding: 20px 10px 0px 5px;
                          font-size: 12px;
                        "
                      >
                        {{ header.text }}
                      </p>

                      <p
                        v-else
                        style="
                          text-align: right;
                          padding: 20px 10px 0px 5px;
                          font-size: 12px;
                        "
                      >
                        {{ header.text }}
                      </p>
                    </th>
                  </tr>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td style="padding-left: 2%; width: 80px">
                      <v-chip :color="getColor(item.classification)" dark>
                        {{ item.classification }}</v-chip
                      >
                    </td>

                    <td
                      style="
                        margin: 10px;
                        text-align: right;
                        padding-right: 10px;
                        width: 120px;
                      "
                    >
                      <div>
                        {{ numberSymbolsFormat(item.bids) }}
                      </div>
                    </td>
                    <td
                      style="
                        margin: 10px;
                        text-align: left;
                        padding-right: 0px;
                        width: 60px;
                      "
                    >
                      <div
                        :id="getClass(item.classification)"
                        :style="item.style_bids"
                        style="padding-left: 2px"
                      >
                        {{ item.percentage_bids }}
                      </div>
                    </td>
                    <td
                      style="
                        margin: 10px;
                        text-align: right;
                        padding-right: 10px;
                      "
                    >
                      <div>
                        {{ numberWithSpaces(item.total_bid_value) }}
                      </div>
                    </td>
                    <td
                      style="
                        margin: 10px;
                        text-align: left;
                        padding-right: 0px;
                        width: 110px;
                      "
                    >
                      <div
                        :id="getClass(item.classification)"
                        :style="item.style_total_bid_value"
                      >
                        <p style="padding: 0px; position: absolute; left: 2px">
                          {{ item.percentage_total_bid_value }}
                        </p>
                      </div>
                    </td>
                    <td
                      style="
                        margin: 10px;
                        text-align: right;
                        padding-right: 10px;
                      "
                    >
                      {{ numberSymbolsFormat(item.impressions) }}
                    </td>
                    <td
                      style="margin: 10px; text-align: left; padding-right: 0px"
                    >
                      <div
                        :id="getClass(item.classification)"
                        :style="item.style_impressions"
                      >
                        <p style="padding: 0px; position: absolute; left: 2px">
                          {{ item.percentage_impressions }}
                        </p>
                      </div>
                    </td>
                    <td
                      style="
                        margin: 10px;
                        text-align: right;
                        padding-right: 10px;
                      "
                    >
                      <div>
                        {{ numberWithSpaces(item.revenues) }}
                      </div>
                    </td>
                    <td
                      style="margin: 10px; text-align: left; padding-right: 0px"
                    >
                      <div
                        :id="getClass(item.classification)"
                        :style="item.style_revenues"
                      >
                        <p style="padding: 0px; position: absolute; left: 2px">
                          {{ item.percentage_revenues }}
                        </p>
                      </div>
                    </td>
                    <td
                      style="
                        margin: 10px;
                        text-align: right;
                        padding-right: 10px;
                      "
                    >
                      {{ item.cpm != null ? item.cpm : 0 }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row style="padding-left: 20px; font-size: 15px; padding-top: 20px">
            <v-col cols="10" style="padding-bottom: 5px">
              What each cumulative category represents :
            </v-col>
            <v-col
              cols="2"
              style="
                text-align: right;
                padding-right: 30px;
                padding-bottom: 0px;
              "
              justify-end
            >
              Last 7 days
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :search="search"
                :items="computedCumultativeThrottling"
                :items-per-page="5"
                class="elevation-1"
                :loading="loading"
                hide-default-footer
                :disable-pagination="true"
              >
                <template slot="header">
                  <tr style="font-size: 13px">
                    <th
                      v-for="header in headersThrottlingCumultative"
                      :key="header.value"
                      style="border-bottom: 0.1px solid lightgray"
                    >
                      <p
                        v-if="header.text == 'Classification'"
                        style="text-align: left; padding: 20px 12px 0px 20px"
                      >
                        {{ header.text }}
                      </p>
                      <p
                        v-else
                        style="text-align: right; padding: 20px 10px 0px 20px"
                      >
                        {{ header.text }}
                      </p>
                    </th>
                  </tr>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td v-if="item.classification == 'A'">
                      <v-chip :color="getColor(item.classification)" dark>
                        {{ item.classification }}</v-chip
                      >
                    </td>
                    <td v-else-if="item.classification == 'AB'">
                      <div id="container">
                        <div id="navi">
                          <v-chip color="#009302" dark> A</v-chip>
                        </div>
                        <div :class="getId('B')">
                          <v-chip
                            color="#22bf25"
                            dark
                            style="margin-left: 18px"
                          >
                            B</v-chip
                          >
                        </div>
                      </div>
                    </td>
                    <td v-else-if="item.classification == 'ABC'">
                      <div id="container">
                        <div id="navi">
                          <v-chip color="#009302" dark> A</v-chip>
                        </div>
                        <div :class="getId('B')">
                          <v-chip
                            color="#22bf25"
                            dark
                            style="margin-left: 18px"
                          >
                            B</v-chip
                          >
                        </div>
                        <div :class="getId('C')">
                          <v-chip
                            color="#42d644"
                            dark
                            style="margin-left: 36px"
                          >
                            C</v-chip
                          >
                        </div>
                      </div>
                    </td>
                    <td v-else-if="item.classification == 'ABCD'">
                      <div id="container">
                        <div id="navi">
                          <v-chip color="#009302" dark> A</v-chip>
                        </div>
                        <div :class="getId('B')">
                          <v-chip
                            color="#22bf25"
                            dark
                            style="margin-left: 18px"
                          >
                            B</v-chip
                          >
                        </div>
                        <div :class="getId('C')">
                          <v-chip
                            color="#42d644"
                            dark
                            style="margin-left: 36px"
                          >
                            C</v-chip
                          >
                        </div>
                        <div :class="getId('D')">
                          <v-chip
                            color="#eaca12"
                            dark
                            style="margin-left: 54px"
                          >
                            D</v-chip
                          >
                        </div>
                      </div>
                    </td>
                    <td v-else-if="item.classification == 'ABCDE'">
                      <div id="container">
                        <div id="navi">
                          <v-chip color="#009302" dark> A</v-chip>
                        </div>
                        <div :class="getId('B')">
                          <v-chip
                            color="#22bf25"
                            dark
                            style="margin-left: 18px"
                          >
                            B</v-chip
                          >
                        </div>
                        <div :class="getId('C')">
                          <v-chip
                            color="#42d644"
                            dark
                            style="margin-left: 36px"
                          >
                            C</v-chip
                          >
                        </div>
                        <div :class="getId('D')">
                          <v-chip
                            color="#eaca12"
                            dark
                            style="margin-left: 54px"
                          >
                            D</v-chip
                          >
                        </div>
                        <div :class="getId('E')">
                          <v-chip
                            color="#f2b009"
                            dark
                            style="margin-left: 72px"
                          >
                            E</v-chip
                          >
                        </div>
                      </div>
                    </td>
                    <td v-else-if="item.classification == 'ABCDEF'">
                      <div id="container">
                        <div id="navi">
                          <v-chip color="#009302" dark> A</v-chip>
                        </div>
                        <div :class="getId('B')">
                          <v-chip
                            color="#22bf25"
                            dark
                            style="margin-left: 18px"
                          >
                            B</v-chip
                          >
                        </div>
                        <div :class="getId('C')">
                          <v-chip
                            color="#42d644"
                            dark
                            style="margin-left: 36px"
                          >
                            C</v-chip
                          >
                        </div>
                        <div :class="getId('D')">
                          <v-chip
                            color="#eaca12"
                            dark
                            style="margin-left: 54px"
                          >
                            D</v-chip
                          >
                        </div>
                        <div :class="getId('E')">
                          <v-chip
                            color="#f2b009"
                            dark
                            style="margin-left: 72px"
                          >
                            E</v-chip
                          >
                        </div>
                        <div :class="getId('F')">
                          <v-chip color="red" dark style="margin-left: 90px">
                            F</v-chip
                          >
                        </div>
                      </div>
                    </td>
                    <td
                      style="
                        padding-bottom: 5px;
                        text-align: right;
                        padding-right: 10px;
                        width: 150px;
                      "
                    >
                      <div>
                        {{ numberSymbolsFormat(item.bids) }}
                      </div>
                    </td>
                    <td
                      style="
                        margin: 10px;
                        text-align: right;
                        padding-right: 10px;
                      "
                    >
                      {{
                        item.percentage_bids != null ? item.percentage_bids : 0
                      }}
                    </td>
                    <td
                      style="
                        text-align: right;
                        width: 200px;
                        padding-right: 10px;
                      "
                    >
                      <div>
                        {{ numberSymbolsFormat(item.impressions) }}
                      </div>
                    </td>
                    <td
                      style="
                        text-align: right;
                        width: 120px;
                        padding-right: 10px;
                      "
                    >
                      {{
                        item.percentage_impressions != null
                          ? item.percentage_impressions
                          : 0
                      }}
                    </td>
                    <td style="text-align: right; padding-right: 10px">
                      {{ numberWithSpaces(item.revenues) }}
                    </td>
                    <td
                      style="
                        text-align: right;
                        width: 150px;
                        padding-right: 10px;
                      "
                    >
                      {{
                        item.percentage_revenues != null
                          ? item.percentage_revenues
                          : 0
                      }}
                    </td>
                    <td
                      style="
                        text-align: right;
                        padding-right: 10px;
                        width: 120px;
                      "
                    >
                      {{ item.cpm != null ? item.cpm : 0 }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row style="padding-left: 20px; font-size: 15px">
            <v-col cols="12">
              Please pick the SSPs that you want to exclude from throttling :
            </v-col>
          </v-row>
          <v-row style="padding-left: 20px">
            <v-icon style="padding: 0px 10px 0px 10px" size="28"
              >mdi-filter-outline</v-icon
            >
            <v-col col="4" style="padding: 15px 0px 20px 0px; z-index: 0">
              <v-select
                v-model="throttlingExclusion"
                label="SSPs to exclude"
                style="z-index: 99"
                flat
                dense
                hide-details
                item-text="name"
                item-value="value"
                small
                multiple
                clearable
                :items="sspListFromApi"
              >
              </v-select>
            </v-col>
            <v-col cols="8" />
          </v-row>
          <v-row style="padding-left: 20px; font-size: 15px; padding-top: 20px">
            <v-col cols="12" style="padding-bottom: 5px">
              Please indicate the sampling you wish to assign to each category :
            </v-col>
          </v-row>
          <v-row style="padding-bottom: 0px; padding-left: 11%">
            <v-col> <v-chip color="#009302" dark> A</v-chip> </v-col>
            <v-col style="padding-left: 10px">
              <v-chip color="#22bf25" dark style="margin-left: 2px"> B</v-chip>
            </v-col>
            <v-col>
              <v-chip color="#42d644" dark style="margin-left: 2px"> C</v-chip>
            </v-col>
            <v-col>
              <v-chip color="#eaca12" dark style="margin-left: 5px"> D</v-chip>
            </v-col>
            <v-col>
              <v-chip color="#f2b009" dark style="margin-left: 10px"> E</v-chip>
            </v-col>
            <v-col>
              <v-chip color="red" dark style="margin-left: 10px"> F</v-chip>
            </v-col>
          </v-row>
          <v-row style="padding-top: 40px; margin: auto; padding-left: 7%">
            <v-col style="padding: 0px">
              <v-slider
                v-model="valueA"
                step="5"
                ticks
                thumb-label="always"
                style="width: 120px"
                min="5"
                ><template v-slot:thumb-label="{ value }">
                  {{ value + '%' }}
                </template></v-slider
              ></v-col
            >
            <v-col style="padding: 0px">
              <v-slider
                v-model="valueB"
                thumb-label="always"
                step="5"
                min="5"
                ticks
                style="width: 120px"
                ><template v-slot:thumb-label="{ value }">
                  {{ value + '%' }}
                </template></v-slider
              ></v-col
            >
            <v-col style="padding: 0px">
              <v-slider
                v-model="valueC"
                thumb-label="always"
                step="5"
                min="5"
                ticks
                style="width: 120px"
                ><template v-slot:thumb-label="{ value }">
                  {{ value + '%' }}
                </template></v-slider
              ></v-col
            >
            <v-col style="padding: 0px">
              <v-slider
                v-model="valueD"
                thumb-label="always"
                step="5"
                min="5"
                ticks
                style="width: 120px"
              >
                <template v-slot:thumb-label="{ value }">
                  {{ value + '%' }}
                </template></v-slider
              ></v-col
            >
            <v-col style="padding: 0px">
              <v-slider
                v-model="valueE"
                thumb-label="always"
                step="5"
                min="5"
                ticks
                style="width: 120px"
              >
                <template v-slot:thumb-label="{ value }">
                  {{ value + '%' }}
                </template></v-slider
              ></v-col
            >
            <v-col style="padding: 0px">
              <v-slider
                v-model="valueF"
                thumb-label="always"
                step="5"
                min="5"
                ticks
                style="width: 120px"
              >
                <template v-slot:thumb-label="{ value }">
                  {{ value + '%' }}
                </template></v-slider
              ></v-col
            >
          </v-row>
          <br />
          <v-spacer />
          <div style="text-align: center">
            <v-btn
              :loading="submitLoading"
              :disabled="submitLoading"
              class="white--text notcapital"
              color="black"
              width="400"
              dense
              @click="submit"
            >
              <v-icon left> mdi-check-circle</v-icon>
              Save this new setting
            </v-btn>
          </div>
          <v-spacer />
          <v-spacer />
        </v-tab-item>
      </v-tabs>
      <v-snackbar v-model="actionsuccess" color="#4CAF4F" :timeout="timeout">
        <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
        {{ messageAction }}
      </v-snackbar>
      <v-snackbar v-model="actionfail" color="red" :timeout="timeout">
        <v-icon size="25" style="padding: 5px">mdi-alert-circle</v-icon>
        {{ messageAction }}
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
  const countries = require('@/assets/styles/countries.js')
  import axios from 'axios'
  import countrySelect from '@/pages/login/components/countrySelect.vue'
  import { SetSelectedSite, SetSelectedCustomer } from '@/store/mutation_types'
  import update_rule from './update_rule.vue'
  import blocklist from './blocklist.vue'
  import archive_rule from './archive_rule.vue'
  import archived_rules from './archived_rules.vue'
  import ruleSitesGroup from './ruleSitesGroup.vue'
  import form_rule from './form_rule.vue'

  import {
    GetSelectedCustomer,
    SaveSelectedCustomer,
    SaveSelectedSite,
    GetUsers
  } from '@/store/action_types'
  import { mapActions, mapGetters, mapMutations } from 'vuex'

  export default {
    name: 'CustomerEdit',
    components: {
      countrySelect,
      blocklist
      // updateRule: update_rule,
      // archiveRule: archive_rule,
      // archivedRules: archived_rules,
      // formRule: form_rule,
      //  sitesgroup: ruleSitesGroup
    },
    data() {
      return {
        customer: {
          name: '',
          code: '',
          cpm_adjustment: 1
        },
        appUser: {},
        customer_details: {},
        selectAll: false,
        countryList: countries,
        updateComplete: false,
        rule: {},
        rules: [],
        selectedSspFiltered: [],
        archivedRules: [],
        sspsTable: [],
        floatRule: [
          (v) => !!v || 'Required',
          (v) => /^\d+((\.\d+)|(\,\d+))?$/.test(v) || 'Decimal only',
          (v) => (v && Number(v) <= 1) || 'Max value : 1',
          (v) => (v && Number(v) > 0) || 'Value > 0'
        ],
        aliasRules: [
          (v) =>
            /^[a-zA-Z 0-9\_]*$/.test(v) ||
            'Special characters are not allowed (except _ )',
          (v) =>
            this.sameSSPname(v) || 'Display name must be different of SSP name'
        ],
        headers: [
          //{text: 'Id', align: 'left', value : 'id'},
          { text: 'Product', align: 'left', value: 'product' },
          { text: 'Site', align: 'left', value: 'site' },
          { text: 'CPM', align: 'left', value: 'cpm_value' },
          { text: 'From', align: 'left', value: 'start_date' },
          { text: 'To', align: 'left', value: 'end_date' },
          { text: 'Actions', align: 'center', value: 'actions' }
        ],
        headersUsers: [
          { text: 'Id', align: 'left', value: 'id' },
          { text: this.$t('last_name'), align: 'left', value: 'last_name' },
          { text: this.$t('first_name'), align: 'left', value: 'first_name' },
          { text: this.$t('customer_id'), align: 'left', value: 'customer_id' },
          { text: this.$t('email'), align: 'left', value: 'email' },
          { text: this.$t('role'), align: 'left', value: 'role' },
          { text: this.$t('status'), align: 'left', value: 'status' },
          { text: this.$t('action'), align: 'left', value: 'action' }
        ],
        headersThrottling: [
          { text: 'Classification', align: 'left' },
          { text: 'Bids', align: 'left' },
          { text: '% Bids', align: 'left' },
          { text: 'Total bids value ($)', align: 'left' },
          { text: '% Total bids value', align: 'left' },
          { text: 'Impressions', align: 'left' },
          { text: '% Impressions', align: 'left' },
          { text: 'Revenue ($)', align: 'left' },
          { text: '% Revenue', align: 'left' },
          { text: 'CPM ($)', align: 'left' }
        ],
        headersThrottlingCumultative: [
          { text: 'Classification', align: 'left' },
          { text: 'Bids', align: 'left' },
          { text: '% Bids', align: 'left' },
          { text: 'Impressions', align: 'left' },
          { text: '% Impressions', align: 'left' },
          { text: 'Revenue ($)', align: 'left' },
          { text: '% Revenue', align: 'left' },
          { text: 'CPM', align: 'left' }
        ],
        bidThrottlingData: {
          seperated: [],
          cumultative: []
        },
        throttlingExclusion: [],
        search: '',
        site: {},
        selectedItem: [],
        required: [(v) => !!v || 'Required'],
        nameRules: [(v) => !!v || this.$t('name is required')],
        submitLoading: false,
        confirmLoading: false,
        loadingrules: false,
        loading: false,
        errors: [],
        errorsrules: [],
        added: false,
        selectedIndex: 0,
        addingRule: false,
        productSelected: false,
        showArchived: false,
        actionsuccess: false,
        actionfail: false,
        timeout: 2000,
        messageAction: '',
        headersSSP: [
          {
            text: this.$t('activation'),
            align: 'left',
            sortable: false,
            value: 'activation'
          },
          {
            text: this.$t('ssp'),
            align: 'left',
            sortable: false,
            value: 'SSP'
          },
          {
            text: this.$t('display_name'),
            align: 'left',
            sortable: false,
            value: 'alias'
          },
          {
            text: this.$t('bid_reduction'),
            align: 'left',
            sortable: false,
            value: 'bid_reduction'
          },
          {
            text: this.$t('geotargeting'),
            align: 'left',
            sortable: false,
            value: 'geotargeting'
          },
          {
            text: this.$t('type'),
            align: 'left',
            sortable: false,
            value: 'type'
          }
        ],
        alias: [],
        aliasJSON: '{',
        searchSSP: '',
        showMore: false,
        loadingSSP: false,
        sspListFromApi: [],
        valueA: 100,
        valueB: 100,
        valueC: 100,
        valueD: 50,
        valueE: 20,
        valueF: 5
      }
    },
    computed: {
      ...mapGetters([
        'customers',
        'users',
        'sites',
        'SspList',
        'selectedSite',
        'appCustomer',
        'selectedCustomer'
      ]),
      countriesShown() {
        let arr = []
        this.countryList.forEach((element) => {
          arr.push({
            code: element.code,
            name: element.name + ' (' + element.code + ')'
          })
        })
        return arr
      },
      sspstableList() {
        if (
          this.sspsTable.length < this.customer_details.length &&
          Object.keys(this.selectedCustomer).length != 0
        ) {
          this.customer_details.forEach((ssp_details) => {
            this.sspsTable.push({
              name: ssp_details['ssp'],
              is_activated: Number(ssp_details['is_activated']),
              cpm_adjustment: ssp_details['cpm_adjustment'],
              exclude:
                ssp_details['country_blacklist'] == null ||
                ssp_details['country_blacklist'] == ''
                  ? false
                  : true,
              country_list:
                ssp_details['country_blacklist'] == null ||
                ssp_details['country_blacklist'] == ''
                  ? this.getCountrylist(ssp_details, 'whitelist')
                  : this.getCountrylist(ssp_details, 'blacklist')
            })
          })
        }
        return this.sspsTable
      },
      computedSeperatedThrottling() {
        if (this.bidThrottlingData.seperated.length > 0) {
          this.bidThrottlingData.seperated.forEach((element) => {
            element.style_bids =
              'width :' +
              (15 + (70 * Number(element.percentage_bids)) / 100) +
              'px'
            element.style_total_bid_value =
              'width :' +
              (10 + (80 * Number(element.percentage_total_bid_value)) / 100) +
              'px'
            element.style_impressions =
              'width :' +
              (10 + (80 * element.percentage_impressions) / 100) +
              'px'
            element.style_revenues =
              'width :' + (10 + (70 * element.percentage_revenues) / 100) + 'px'
          })
        }
        return this.bidThrottlingData.seperated
      },
      computedCumultativeThrottling() {
        return this.bidThrottlingData.cumultative
      },
      computedSites() {
        return this.sites
          .filter((el) => {
            return el.is_archived == 0
          })
          .sort((a, b) => a.name.localeCompare(b.name))
      },
      computedUsers() {
        return this.users.filter((el) => {
          return el.customer_id == this.selectedCustomer.id
        })
      }
    },
    watch: {
      selectedSite() {
        this.site = this.selectedSite
      },
      async selectedSspFiltered() {
        await this.getDataThrottlingFromApi()
      },
      async appCustomer() {
        await this.GetUsers()
        this.customer = this.appCustomer
        this.SetSelectedCustomer(this.appCustomer)
        this.sspsTable = []
        this.customer_details = {}
        this.bidThrottlingData = {
          seperated: [],
          cumultative: []
        }
        let view = this.$route.query.settings
        if (this.$route.params.customer_id != this.appCustomer.id) {
          await this.$router.push({
            name: 'customer_edit',
            params: {
              customer_id: this.appCustomer.id
            }
          })
        }
        this.$router.push(this.$route.path + '?settings=' + view)
        this.getAllCustomerInformations()
      }
    },
    async created() {
      if (this.$store.getters.appUser != undefined)
        this.appUser = Object.assign({}, this.$store.getters.appUser)
      switch (this.$route.query.settings) {
        case 'setup':
          this.selectedIndex = 2
          break
        case 'ssp':
          this.selectedIndex = 3
          break
        // case 'billing':
        //  this.selectedIndex = 3
        //  break
        case 'user':
          this.selectedIndex = 1
          break
        case 'blocklist':
          this.selectedIndex = 4
          break
        case 'throttling':
          this.selectedIndex = 5
          break
        default:
          this.selectedIndex = 0
      }
      if (this.$route.query.rule) {
        if (this.$route.query.rule == 'new') this.addingRule = true
      }

      if (this.$route.name != 'customer_new') {
        this.getAllCustomerInformations()
      }
      console.log(this.appUser)
    },
    methods: {
      ...mapMutations([SetSelectedSite, SetSelectedCustomer]),
      ...mapActions([
        GetSelectedCustomer,
        SaveSelectedCustomer,
        SaveSelectedSite,
        GetUsers
      ]),
      async getAllCustomerInformations() {
        await this.GetSelectedCustomer(this.$route.params.customer_id)
        await this.GetUsers()
        this.customer = this.selectedCustomer
        this.getDataFromApi()
        this.getCustomerDetails()
        await this.getDataThrottlingFromApi()
        if (this.selectedCustomer.alias != '')
          this.alias = JSON.parse(this.selectedCustomer.alias)
      },

      async getCustomerDetails() {
        const customerDetails = await axios.get(
          `/api/getcustomerdetailsbyid/${this.$route.params.customer_id}`
        )
        let resultFromApi = customerDetails.data
        let filteredResult = []
        let testSSP = []
        resultFromApi.forEach((element) => {
          this.SspList.forEach((ssp) => {
            if (element.ssp == 'test') testSSP.push(element)
            else if (element.ssp == ssp) {
              filteredResult.push(element)
            }
          })
        })
        this.customer_details = filteredResult.sort((a, b) =>
          this.$t(a.ssp).localeCompare(this.$t(b.ssp))
        )
        this.customer_details.push(testSSP[0])
      },
      async getDataThrottlingFromApi() {
        const payload = {
          filters: this.selectedSspFiltered,
          customerId: this.$route.params.customer_id
        }
        try {
          const response = await axios.post(
            `/api/getbidthrottlingdata`,
            payload
          )
          if (response.data.seperated.length > 0) {
            this.bidThrottlingData.seperated = response.data.seperated
          }
          if (response.data.cumultative.length > 0) {
            this.bidThrottlingData.cumultative = response.data.cumultative
          }
          if (response.data.ssps.length > 0) {
            response.data.ssps.forEach((element) => {
              this.sspListFromApi.push({
                name: this.$t(element.ssp),
                value: element.ssp
              })
            })
          }
          this.valueA = Number(this.selectedCustomer.a_perf_throttling) * 100
          this.valueB = Number(this.selectedCustomer.b_perf_throttling) * 100
          this.valueC = Number(this.selectedCustomer.c_perf_throttling) * 100
          this.valueD = Number(this.selectedCustomer.d_perf_throttling) * 100
          this.valueE = Number(this.selectedCustomer.e_perf_throttling) * 100
          this.valueF = Number(this.selectedCustomer.f_perf_throttling) * 100
          if (this.selectedCustomer.throttling_exclusion != '') {
            var newStr = this.selectedCustomer.throttling_exclusion.replace(
              /"/g,
              ''
            )
            newStr = newStr.substring(1, newStr.length - 1)
            this.throttlingExclusion = newStr.split(',')
          }
        } catch (error) {
          console.log('error')
          return
        }
      },
      getCountrylist(ssp_details, type) {
        var str = ssp_details['country_' + type]
        if (str != '' && str != null) {
          let substr = str.substring(2, str.length - 2)
          var arr = substr.split('","')
          return arr
        }
        return []
      },
      getNameCountry(code) {
        var index = this.countryList.findIndex((obj) => obj.code == code)
        if (index != -1) return this.countryList[index].name
        else return ''
      },
      async submit() {
        if (this.$refs.form != undefined) {
          if (!this.$refs.form.validate()) {
            console.log('No fullfilled')
            return
          }
        }
        this.submitLoading = true
        this.manageCountriesList()
        this.manageThrottling()
        this.saveCustomerDetails()
        this.saveCustomer()
      },
      async saveCustomerDetails() {
        for (let i = 0; i < this.sspstableList.length; i++) {
          //CPM ADJUSTEMENT
          this.customer_details[i]['cpm_adjustment'] =
            this.sspstableList[i]['cpm_adjustment']
          //IS ACTIVATED
          this.customer_details[i]['is_activated'] =
            this.sspstableList[i]['is_activated']
          //ALIAS
        }
        this.manageDisplayNames()
        // if (this.$route.name != 'customer_new') await this.saveLogsCpm()
        this.customer_details.forEach(async (element) => {
          await axios.post(`/api/savecustomerdetails`, element)
        })
      },
      manageDisplayNames() {
        var obj = this.alias
        Object.keys(obj).forEach((key) => {
          var val = obj[key].toLowerCase()
          if (val != '') {
            this.aliasJSON += '"' + key + '":"' + val + '",'
          }
        })
        this.aliasJSON = this.aliasJSON.slice(0, -1)
        this.aliasJSON += '}'
        if (this.aliasJSON == '}') this.aliasJSON = null
        this.customer.alias = this.aliasJSON
        this.aliasJSON = '{'
      },
      async saveCustomer() {
        this.customer.code = this.customer.name
          .trim()
          .replace(/\s+/g, '-')
          .toLowerCase()
        this.customer.cpm_adjustment = this.customer.cpm_adjustment
          .toString()
          .replace(',', '.')
        let ret = await this.SaveSelectedCustomer(this.customer).catch(() => {
          this.errors = this.getErrors
          this.submitLoading = false
          return false
        })
        this.actionsuccess = true
        this.messageAction = 'Customer updated successfully'
        setTimeout(() => (this.submitLoading = false), 1500)
        if (!ret) {
          return
        }
        if (this.$route.name == 'customer_new') {
          setTimeout(() => this.$router.push('/customers'), 1000)
        }
      },
      getFormatThrottling() {
        return (
          '[{"perf_throttling":{"a":' +
          this.customer.a_perf_throttling +
          ',"b":' +
          this.customer.b_perf_throttling +
          ',"c":' +
          this.customer.c_perf_throttling +
          ',"d":' +
          this.customer.d_perf_throttling +
          ',"e":' +
          this.customer.e_perf_throttling +
          ',"f":' +
          this.customer.f_perf_throttling +
          '}, "exclusion":' +
          this.customer.throttling_exclusion +
          '}]'
        )
      },
      triggerChangesThrottling() {
        if (
          this.selectedCustomer.a_perf_throttling !=
            this.customer.a_perf_throttling ||
          this.selectedCustomer.b_perf_throttling !=
            this.customer.b_perf_throttling ||
          this.selectedCustomer.c_perf_throttling !=
            this.customer.c_perf_throttling ||
          this.selectedCustomer.d_perf_throttling !=
            this.customer.d_perf_throttling ||
          this.selectedCustomer.e_perf_throttling !=
            this.customer.e_perf_throttling ||
          this.selectedCustomer.f_perf_throttling !=
            this.customer.f_perf_throttling ||
          this.selectedCustomer.throttling_exclusion !=
            this.customer.throttling_exclusion
        ) {
          return true
        } else return false
      },
      async saveLogsCpm() {
        const customerURL = await axios.get(
          `/api/getactivatedcustomerdetailsbyid/${this.selectedCustomer.id}`
        )
        var customerdetailsdb = customerURL.data
        for (const ssp of this.sspstableList) {
          customerdetailsdb.forEach(async (element) => {
            if (element.ssp == ssp.name) {
              if (
                element.cpm_adjustment != ssp.cpm_adjustment ||
                element.country_whitelist != ssp.country_whitelist ||
                element.country_blacklist != ssp.country_blacklist
              ) {
                var cpmlogs = {
                  user: this.$store.getters.appUser.name,
                  country_whitelist: ssp.country_whitelist,
                  country_blacklist: ssp.country_blacklist,
                  customer_id: element.customer_id,
                  ssp: element.ssp,
                  cpm_value: ssp.cpm_adjustment,
                  throttling: this.getFormatThrottling()
                }
                await axios.post(`/api/customers/cpmlogs`, cpmlogs)
              }
            }
          })
        }
        if (this.triggerChangesThrottling()) {
          var cpmlogs = {
            user: this.$store.getters.appUser.name,
            country_whitelist: null,
            country_blacklist: null,
            customer_id: this.selectedCustomer.id,
            ssp: 'ALL',
            cpm_value: 1,
            throttling: this.getFormatThrottling()
          }
          await axios.post(`/api/customers/cpmlogs`, cpmlogs)
        }
      },
      getFormatArray(array) {
        if (array.length == 0) return ''
        var str_toReturn = ''
        str_toReturn = '['
        array.forEach((x) => {
          if (array.indexOf(x) != array.length - 1)
            str_toReturn += '"' + x + '"' + ','
          else str_toReturn += '"' + x + '"'
        })
        str_toReturn += ']'
        return str_toReturn
      },
      manageThrottling() {
        this.customer.a_perf_throttling = this.valueA / 100
        this.customer.b_perf_throttling = this.valueB / 100
        this.customer.c_perf_throttling = this.valueC / 100
        this.customer.d_perf_throttling = this.valueD / 100
        this.customer.e_perf_throttling = this.valueE / 100
        this.customer.f_perf_throttling = this.valueF / 100
        var throttlingExclusionText = this.getFormatArray(
          this.throttlingExclusion
        )
        this.customer.throttling_exclusion = throttlingExclusionText
      },
      manageCountriesList() {
        for (let index = 0; index < this.sspstableList.length; index++) {
          if (this.sspstableList[index]['country_list'].length > 0) {
            if (this.sspstableList[index]['exclude'] == false) {
              this.fillCustomerWithCountries(
                index,
                this.sspstableList[index],
                'whitelist'
              )
              this.emptyCountriesInCustomer(index, 'blacklist')
            } else {
              this.fillCustomerWithCountries(
                index,
                this.sspstableList[index],
                'blacklist'
              )
              this.emptyCountriesInCustomer(index, 'whitelist')
            }
          } else {
            if (this.sspstableList[index]['exclude'] == false) {
              this.emptyCountriesInCustomer(index, 'whitelist')
            } else {
              this.emptyCountriesInCustomer(index, 'blacklist')
            }
          }
        }
      },
      fillCustomerWithCountries(index, element, type) {
        this.customer_details[index]['country_' + type] = ''
        this.customer_details[index]['country_' + type] = '['
        element['country_list'].forEach((x) => {
          if (
            element['country_list'].indexOf(x) !=
            element['country_list'].length - 1
          ) {
            this.customer_details[index]['country_' + type] +=
              '"' + x + '"' + ','
          } else {
            this.customer_details[index]['country_' + type] += '"' + x + '"'
          }
        })
        this.customer_details[index]['country_' + type] += ']'
      },
      emptyCountriesInCustomer(index, type) {
        this.customer_details[index]['country_' + type] = null
      },
      async addRule(rule) {
        this.errorsrules = []
        this.confirmLoading = true
        try {
          const response = await axios.post(`/api/rules`, rule)
          const errormessage = response.data
          if (errormessage != true) {
            this.errorsrules.push(errormessage)
          } else {
            this.added = true
            setTimeout(() => (this.added = false), 2000)
            this.addingRule = false
            this.$router.push(this.$route.path + '?settings=billing')
          }
          this.confirmLoading = false
          this.getDataFromApi()
        } catch (e) {
          console.error(e)
          this.confirmLoading = false
        }
      },
      remove(item, index) {
        item.splice(item.indexOf(item[index]), 1)
        item = [...item]
      },
      newItem() {
        this.$router.push(this.$route.path + '/users/new')
      },
      countryUpdate(country) {
        this.customer.country = country
      },
      back() {
        this.$router.back()
      },
      getPosition(text, subString, index) {
        return text.split(subString, index).join(subString).length
      },
      goTo(view) {
        if (this.$route.fullPath != this.$route.path + '?settings=' + view)
          this.$router.push(this.$route.path + '?settings=' + view)
      },
      updateSsp(elem) {
        this.customer[elem['key']] = elem['value']
      },
      async getDataFromApi() {
        this.loading = true
        try {
          const firstresponse = await axios.get(
            `/api/getrulessitesgroups/${this.customer.id}`
          )
          this.rules = firstresponse.data

          const secondresponse = await axios.get(
            `/api/getrulesarchivedsitesgroups/${this.customer.id}`
          )
          this.archivedRules = secondresponse.data

          this.loading = false
        } catch (e) {
          console.error(e)
          this.loading = false
        }
      },
      format_date(item) {
        var myDate = item.substr(0, 10).split('-')
        var datetoreturn = myDate[2] + '/' + myDate[1] + '/' + myDate[0]

        return datetoreturn
      },
      sameSSPname(val) {
        if (val != undefined) {
          var key = Object.keys(this.alias).find(
            (key) => this.alias[key] === val
          )
          var valueLow = val.toLowerCase()
          if (key == valueLow) return false
        }
        return true
      },
      async archiveRule(item) {
        var objrule = {}
        objrule.id = item.sites[0].rule_id
        objrule.product = item.product
        objrule.cpm_value = item.cpm_value
        objrule.site_id = item.sites[0].id
        objrule.customer_id = item.customer_id
        objrule.start_date = item.sites[0].start_date
        //await axios.post(`/api/archiverule`, objrule)

        if (item.sites[0].name != 'ALL') {
          for (let j = 0; j < item.sites.length; j++) {
            try {
              objrule.site_id = item.sites[j].id
              await axios.post(`/api/archiverule`, objrule)
            } catch (e) {
              console.error(e)
            }
          }
        } else {
          try {
            await axios.post(`/api/archiverule`, objrule)
          } catch (e) {
            console.error(e)
          }
        }

        this.getDataFromApi()
      },
      Status(status) {
        switch (status) {
          case 'ACTIVE':
            return this.$t('active')
          case 'DEPROVISIONED':
            return this.$t('deactivated')
          case 'SUSPENDED':
            return this.$t('suspended')
          case 'PASSWORD_EXPIRED':
            return this.$t('pass_expired')
          case 'PROVISIONED':
            return this.$t('pending')
          case 'RECOVERY':
            return this.$t('recovery')
          case 'PENDING':
            return this.$t('pending')
          default:
            return this.$t('deleted')
        }
      },
      async ActionUser(action, email) {
        await axios.post(`/api/${action}/${email}`)
        const rr = await axios.post(`/api/updatestatus`)
        await this.GetUsers()
      },
      editItem(user) {
        this.$router.push(this.$route.path + '/users/' + user.id)
      },
      numberWithSpaces(x) {
        if (x != null) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        else return 0
      },
      getClass(classification) {
        return 'pointer' + classification
      },
      getId(classification) {
        return 'infoi info' + classification
      },
      getColor(classification) {
        switch (classification) {
          case 'A':
            return '#009302'
            break
          case 'B':
            return '#22bf25'
            break
          case 'C':
            return '#42d644'
            break
          case 'D':
            return '#eaca12'
            break
          case 'E':
            return '#f2b009'
            break
          default:
            return 'red'
            break
        }
      },
      async saveBlocklist() {
        this.customer.iab_blocklist = this.selectedCustomer.iab_blocklist
        this.customer.domain_blocklist = this.selectedCustomer.domain_blocklist
        this.customer.geotargeting_blacklist =
          this.selectedCustomer.geotargeting_blacklist
        this.customer.geotargeting_whitelist =
          this.selectedCustomer.geotargeting_whitelist
        await this.saveCustomer()
      },
      numberSymbolsFormat(item) {
        item = parseInt(item)
        let pref = ''
        let num = ''
        if (item >= 1000000000) {
          pref = 'g'
          num = (item / 1000000000).toFixed(1)
        } else if (item >= 1000000) {
          pref = 'm'
          num = (item / 1000000).toFixed(1)
        } else if (item >= 1000) {
          pref = 'k'
          num = (item / 1000).toFixed(1)
        } else {
          num = item
        }
        return this.numberWithSpaces(num) + ' ' + pref
      }
    }
  }
</script>

<style scoped>
  .avatar {
    padding-left: 20px;
    padding-bottom: 23px;
  }
  .no-shadow,
  .no-shadow::before {
    box-shadow: none;
    background-color: transparent !important;
  }
  .combobox {
    padding-left: 0px;
    padding-top: 0px;
  }
  .selectedRow {
    background-color: red;
    font-weight: bold;
  }
  .selectedRows {
    background-color: blue;
    font-weight: bold;
  }
  #pointerA {
    width: 50px;
    height: 40px;
    position: relative;
    background: #009302;
    padding: 8px;
    color: white;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding-left: 5px;
    padding-right: 0px;
  }
  #pointerA:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
  #pointerA:before {
    content: '';
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid #009302;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }

  #pointerB {
    width: 50px;
    height: 40px;
    position: relative;
    background: #22bf25;
    padding: 8px;
    color: white;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-left: 5px;
    padding-right: 0px;
  }
  #pointerB:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
  #pointerB:before {
    content: '';
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid #22bf25;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }

  #pointerC {
    width: 50px;
    height: 40px;
    position: relative;
    background: #42d644;
    padding: 8px;
    color: white;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-left: 5px;
    padding-right: 0px;
  }
  #pointerC:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
  #pointerC:before {
    content: '';
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid #42d644;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }

  #pointerD {
    width: 50px;
    height: 40px;
    position: relative;
    background: #eaca12;
    padding: 8px;
    color: white;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-left: 5px;
    padding-right: 0px;
  }
  #pointerD:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
  #pointerD:before {
    content: '';
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid #eaca12;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }

  #pointerE {
    width: 50px;
    height: 40px;
    position: relative;
    background: #f2b009;
    padding: 8px;
    color: white;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-left: 5px;
    padding-right: 0px;
  }
  #pointerE:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
  #pointerE:before {
    content: '';
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid #f2b009;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }

  #pointerF {
    width: 50px;
    height: 40px;
    position: relative;
    background: red;
    padding: 8px;
    color: white;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-left: 5px;
    padding-right: 0px;
  }
  #pointerF:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
  #pointerF:before {
    content: '';
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid red;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
  #container {
    position: relative;
    padding-bottom: 30px;
  }
  #navi,
  .infoi {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .infoB {
    z-index: 10;
  }
  .infoC {
    z-index: 20;
  }
  .infoD {
    z-index: 30;
  }
  .infoE {
    z-index: 40;
  }
  .infoF {
    z-index: 50;
  }
</style>
